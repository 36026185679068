import {cva} from 'class-variance-authority';
import twMerge from 'classnames';

import Typography from '@/components/base/elements/Typography/Typography';
import {useSiteData} from '@/hooks/useSiteData';

const styles = cva('font-graphik', {
  variants: {
    mode: {
      light: 'fill-black',
      customLight: 'fill-legacy-cyan-90',
      dark: 'fill-white',
    },
  },
});

export interface StatProps {
  metric: string;
  description: string;
  mode?: 'light' | 'customLight' | 'dark';
  viewBox?: string;
  scale?: number;
}

export default function Stat({
  mode = 'light',
  metric,
  description,
  viewBox = '0 0 750 300',
  scale = 1,
}: StatProps) {
  // Set the y offset for the text, otherwise it's too far/close to the description
  const y = Number(viewBox.split(' ').slice(-1));
  const yOffset = Math.round(y - y / 6);

  // Temporarily scale down metrics in some locales due to font issues
  // TODO: Remove once we can separate the suffixes/prefixes from the stat
  const {site} = useSiteData();
  const scaledDownMetricLocale = ['ja', 'zh-CN', 'zh-TW', 'fr', 'fr-BE', 'it'];
  if (scaledDownMetricLocale.includes(site.locale)) {
    viewBox = '0 0 1300 300';
  }

  return (
    <div>
      <svg viewBox={viewBox}>
        <text
          style={{'--stat-scale': scale} as React.CSSProperties}
          className={twMerge(
            styles({mode}),
            `text-[calc(var(--stat-scale)*18.75rem)]`,
          )}
          x="0"
          y={yOffset}
        >
          {metric}
        </text>
      </svg>
      <Typography as="p">{description}</Typography>
    </div>
  );
}
